<template>
  <div>
    <c-card title="작업위치(지도)" class="cardClassDetailForm" :noMarginPadding="true">
      <template slot="card-button">
        <q-btn-group outline>
          <c-btn v-if="editable&&!popupParam.disabled&&sopMapId" label="추가" icon="add" @btnClicked="addArea"/>
          <c-btn v-if="editable&&!popupParam.disabled&&sopMapId" label="적용" icon="check" @btnClicked="applyArea"/>
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <template v-if="sopMapId">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-8 container">
            <q-img
              :src="mapSrc"
              spinner-color="white"
              :contain="true"
              style="width:750px"
            >
              <template v-if="popupParam.maps && popupParam.maps.length > 0">
                <VueDraggableResizable
                  v-for="(item, idx) in popupParam.maps"
                  :key="idx"
                  ref="markImage"
                  class="markImage"
                  :resizable="false"
                  :parent="true"
                  :draggable="editable&&!popupParam.disabled"
                  :x="item.locationXcoordinate"
                  :y="item.locationYcoordinate"
                  :w="30" :h="30"
                  :grid="[30, 30]"
                  @dragging="(x, y) => onDrag(x, y, item)"
                >
                  <!-- @activated="clickMark(item)" -->
                  {{idx + 1}}
                </VueDraggableResizable>
              </template>
            </q-img>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-4 container">
            <template v-if="popupParam.maps && popupParam.maps.length > 0">
              <div class="row"
                v-for="(item, idx) in popupParam.maps"
                :key="idx">
                <div class="col-8" style="position:relative;top:15px;">
                  <c-text
                    :editable="editable"
                    :disabled="popupParam.disabled"
                    :label="idx + 1 + '번 위치상세내용'"
                    name="mapContent"
                    v-model="item.mapContent"
                    @dataChange="dataChange(item)">
                  </c-text>
                </div>
                <div class="col-4" style="position:relative;top:20px;">
                  <c-btn v-if="editable&&!popupParam.disabled" label="제외" icon="remove" @btnClicked="removePoint(idx)"/>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <q-chip square icon="info" style="width:100%">
            {{mapMessage}}
          </q-chip>
        </template>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'risk-assess-map',
  components: { VueDraggableResizable },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          riskAssessmentTargetId: '',  
          isAll: false,
          maps: [],
          deleteMaps: [],
          sopMapId: '',
          stepCd: '',
          disabled: false,
        }
      }
    },
  },
  data() {
    return {
      mapMessage: '',
      mapSrc: require('@/assets/images/no-image.png'),
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      sopMapId: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
    sopMapId() {
      this.$comm.previewImageParamTask(
        this.sopMapId,
        'WORK_MAP'
      ).then(_result => {
        this.mapSrc = _result;
      });
    },
    'popupParam.sopMapId'() {
      this.setMap();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting

      this.setMap();
    },
    setMap() {
      if (this.popupParam.sopMapId) {
        this.sopMapId = this.$_.clone(this.popupParam.sopMapId);
      } else {
        this.mapMessage = '작업장소를 선택바랍니다.'
        if (!this.popupParam.deleteMaps) {
          this.popupParam.deleteMaps = []
        }
        this.$_.forEach(this.popupParam.maps, map => {
          if (this.$_.findIndex(this.popupParam.deleteMaps, { riskAssessMapId: map.riskAssessMapId }) === -1
            && map.editFlag !== 'C') {
            this.popupParam.deleteMaps.push(map)
          }
        })
        this.sopMapId = '';
        this.mapSrc = '';
        this.popupParam.maps = [];
      }
    },
    addArea() {
      this.popupParam.maps.push({
        riskAssessMapId: uid(),
        riskAssessmentTargetId: this.popupParam.riskAssessmentTargetId,  // 작업허가서 일련번호
        sopMapId: this.sopMapId,  // 지도 일련번호
        locationXcoordinate: 0,  // 작업위치 X좌표
        locationYcoordinate: 0,  // 작업위치 Y좌표
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C',
      })
    },
    onDrag: function (x, y, item) {
      item.locationXcoordinate = x
      item.locationYcoordinate = y

      if (item.editFlag !== 'C') {
        item.editFlag = 'U'
        item.chgUserId = this.$store.getters.user.userId
      }
    },
    removePoint(idx) {
      if (!this.popupParam.deleteMaps) {
        this.popupParam.deleteMaps = [];
      }
      if (this.$_.findIndex(this.popupParam.deleteMaps, { riskAssessMapId: this.popupParam.maps[idx].riskAssessMapId }) === -1
        && this.popupParam.maps[idx].editFlag !== 'C') {
        this.popupParam.deleteMaps.push(this.popupParam.maps[idx])
      }
      this.popupParam.maps.splice(idx, 1)
    },
    dataChange(item) {
      if (item.editFlag !== 'C') {
        item.editFlag = 'U'
        item.chgUserId = this.$store.getters.user.userId
      }
    },
    applyArea() {
      this.$emit('closePopup', 
      { 
        maps: this.popupParam.maps, 
        deleteMaps: this.popupParam.deleteMaps, 
        type: 'APPLY'
      });
    },
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important

</style>